<template>
    <div class="area">
        <div class="flex-grow flex-box vertical align-center">
            <h2 class="not-found">403 您没有权限</h2>
            <router-link :to="{name: 'Home'}" replace class="back-home">返回首页</router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: "NotFound"
    }
</script>

<style scoped lang="less">
    .not-found{
        margin: 200px auto 0;
    }
    .back-home {
        margin-top: 30px;
        font-size: 16px;
    }
</style>
